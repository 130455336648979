
// Fonts
@import url(https://fonts.googleapis.com/css?family=Nunito:400,600,700);

@import "variables";
@import "node_modules/select2/src/scss/core";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "components";
@import "pages";
@import "layout";

/*
|--------------------------------------------------------------------------
| General
|--------------------------------------------------------------------------
*/


/*
|--------------------------------------------------------------------------
| Select2
|--------------------------------------------------------------------------
*/

.select2-container .select2-selection--single {
    height: 39px !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 39px !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 39px !important;
}
/*
|--------------------------------------------------------------------------
| Table
|--------------------------------------------------------------------------
*/

.table-bordered > thead > tr > th, .table-bordered > thead > tr > td {
    border-bottom-width: 2px;
    background-color: #f1f1f1;
}

/*
|--------------------------------------------------------------------------
| Misc
|--------------------------------------------------------------------------
*/

.btn-black {
    color: #fff;
    background-color: #2a2d33;
    border-color: #1f2023;
}
.btn-black:hover {
    color: #d6d6d6;
    background-color: #2a2d33;
    border-color: #1f2023;
}
.btn-secondary {
    color: #fff;
    background-color: #868e96;
    border-color: #868e96;
}
.btn-secondary:hover {
    color: #fff;
    background-color: #727b84;
    border-color: #6c757d;
}

/*
|--------------------------------------------------------------------------
| Misc
|--------------------------------------------------------------------------
*/

.featured-color {
    color: $brand-primary;
}

.well {
    background-color: #ffffff;
    border-radius: 0px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.flat-well {
    background-color: #ffffff;
    border-radius: 0px;
    padding: 0px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    min-height: 20px;
    margin-bottom: 20px;
    border: 0px;
}

.table > tbody > tr > td {
    vertical-align: middle;
}

.nav-stacked {
    a {
        color: #2a2d33;
    }

    .divider {
        height: 1px;
        margin: 11px 0;
        overflow: hidden;
        background-color: #e5e5e5;
    }

    .fa {
        margin-right: 9px;
        font-size: 14px;
    }
}

.bmargin-20 {
    margin-bottom: 20px;
}
