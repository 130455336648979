.report-section-title {
    font-size: 20px;
    margin-top: 25px;
    margin-bottom: 25px;

    .fa {
        margin-right: 10px;
    }
}

.report-item {
    min-height: 20px;
    padding: 15px;
    margin-bottom: 20px;
    background-color: #ffffff;
    border: 1px solid #e3e3e3;
    border-radius: 0px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);

    text-align: center;
    font-size: 18px;

    p {
        padding-top: 10px;
        font-size: 14px;
        margin: 0px
    }
}
