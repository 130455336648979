
// Body
$body-bg: #f7f7f7;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
// $panel-default-border: $laravel-border-color;
// $panel-inner-border: $laravel-border-color;

// Brands
$brand-primary: #0c71ef;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 15px;
$line-height-base: 1.6;
$text-color: #252525;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #f3f3f3;

/* ==============================
*   Layout
================================ */
$page-wrapper-background: #f7f7f7;

// Top Nav
$top-nav-background: #0c71ef;
$top-nav-border: #ececec;
$navbar-height: 60px !default;

// Side Nav
$background-color: #272727;
$side-nav-background: #272727;
$side-nav-link: #fff;
$side-nav-active: #fff;
