.auth {
    padding: 100px 0px;
    background-color: #f5f5f5;

    .panel-default {
        border-color: #e4e4e4;
    }

    .panel-default > .panel-heading {
        border-color: #e4e4e4;
    }
    
}
