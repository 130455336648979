.delivery-item {

    border: 1px solid #ececec;
    margin: 5px 5px 10px 5px;

    .delivery-content {
        padding: 10px;
    }

    p {
        margin: 0px;
        text-align: center;
    }
}
