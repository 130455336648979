
.quick-stats {
    background: white;
    margin: 0 0 20px 0;
    padding: 20px;

    .col-md-2, .col-md-3 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .qs-item {
        border-right: 1px solid #ececec;
        text-align: center;
        color: #989898;
        .featured {
            font-size: 35px;
            color: black;
        }
    }
    .qs-item-last {
        border-right: none;
        text-align: center;
        color: #989898;
        .featured {
            font-size: 35px;
            color: black;
        }
    }
}
