.order-stage {
    padding: 3px 6px;
    font-size: 14px;
}
.status {
    &.green::before {
        content: '';
        display: inline-block;
        margin: 0 7px 2px 0;
        width: 7px;
        height: 7px;
        background-color: #46bb30;
        border-color: #358825;
        box-shadow: 0px 0px 2px 1px #3aa525;
        border-radius: 7.5px;
    }
    &.orange::before {
        content: '';
        display: inline-block;
        margin: 0 7px 2px 0;
        width: 7px;
        height: 7px;
        background-color: #ff8100;
        border-color: #ffb100;
        box-shadow: 0px 0px 2px 1px #ff7600;
        border-radius: 7.5px;
    }
    &.red::before {
        content: '';
        display: inline-block;
        margin: 0 7px 2px 0;
        width: 7px;
        height: 7px;
        background-color: #ff0000;
        border-color: #d40c0c;
        box-shadow: 0px 0px 2px 1px #ff0000;
        border-radius: 7.5px;
    }
}
