.product-nav {
    .nav-tabs {
        border-bottom: 0px;
    }

    .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
        color: #555555;
        background-color: #ffffff;
        border: 1px solid #ddd;
        border-bottom-color: transparent;
        cursor: default;
    }

    .nav-tabs > li > a {
        border-radius: 0;
    }

    .nav > li > a {
        padding: 14px 18px;
    }
}
